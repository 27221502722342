import $ from 'jquery';

$(document).ready(function() {

    // IMAGES
    $('.slider-images').slick({
        dots: true,
        arrows: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        variableWidth: true,
        centerMode: true,
        centerPadding: '10%',
        mobileFirst: true,
        responsive: [
			{
				breakpoint: 900,
				settings: {
					centerPadding: '50%',
				}
			},
		]
    });

}); 