
let navbar = document.querySelector(".navbar");
let navMain = document.querySelector(".nav-main");
//let navLinks = navMain.querySelectorAll(".nav-main-list__link");

let navToggleButtons = document.querySelectorAll('.js-nav-toggle');

document.addEventListener('DOMContentLoaded', function () {
	
	for (i = 0; i < navToggleButtons.length; ++i) {
		navToggleButtons[i].onclick = function() {
			navbar.classList.toggle('is-open');
		}
	}
	
}, false);