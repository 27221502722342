import $ from 'jquery';
import "jquery";
import popper from 'popper.js';

// SLICK SLIDER
import './vendors/slick/slick.min';


// OLD WAY: import bootstrap from 'bootstrap';
/************  BOOTSTRAP 5 **************/
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/base-component';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';


// COMPONENTS
import './components/nav-toggle'
import "./components/slick-slider";
// import "./components/google-map";
// import Splitting from "splitting";


